import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from "../components/OilChanger/HeroSection";
import TestimonialsSection from "../components/TestimonialsSection";
import CallToAction from "../components/OilChanger/CallToAction";
import FeaturesSection from "../components/OilChanger/FeaturesSection";
import PricingPage from "./PricingPage";

const OilChangeLandingPage = () => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'page_type': 'oil_change_landing',
            'industry': 'oil_change'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>GarageHero - Sistema para Troca de Óleo Automotivo</title>
                <meta name="description"
                      content="Sistema completo para gestão de troca de óleo automotivo. Controle agendamentos, estoque e clientes de forma eficiente com o GarageHero." />
                <meta property="og:title" content="GarageHero - Sistema para Troca de Óleo Automotivo" />
                <meta property="og:description"
                      content="Sistema completo para gestão de troca de óleo automotivo. Organize processos, controle clientes e automatize tarefas com facilidade." />
                <meta property="og:url" content="https://troca-de-oleo.garagehero.com" />
                <meta name="keywords"
                      content="sistema para troca de óleo, gestão de troca de óleo, software para troca de óleo, automação de troca de óleo, controle de processos de troca de óleo" />
            </Helmet>
            <HeroSection />
            <FeaturesSection />
            <TestimonialsSection />
            <PricingPage />
            <CallToAction />
        </>
    );
};

export default OilChangeLandingPage;
