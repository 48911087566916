import React from "react";
import {ArrowRight} from "lucide-react";

const FeaturesSectionWithCTA = () => {
    const features = [
        {
            title: "Gestão Completa e Simplificada",
            description: "Tenha o controle total do seu negócio",
            points: [
                {
                    title: "Cadastro Inteligente",
                    text: "Gerencie clientes, veículos, produtos e serviços em uma única plataforma",
                    icon: "📋"
                },
                {
                    title: "Consulta de Placa",
                    text: "Obtenha informações completas do veículo apenas com a placa",
                    icon: "🚗"
                },
                {
                    title: "Gestão Visual em TV",
                    text: "Acompanhe serviços em tempo real com Kanban 4K em tela grande",
                    icon: "📺"
                },
                {
                    title: "Gestão de Agenda",
                    text: "Organize seus serviços com calendário e lembretes automáticos",
                    icon: "📅"
                }
            ]
        },
        {
            title: "Vendas e Atendimento",
            description: "Mais agilidade nas suas operações",
            points: [
                {
                    title: "Integração WhatsApp",
                    text: "Envie orçamentos e receba aprovações direto pelo WhatsApp",
                    icon: "📱"
                },
                {
                    title: "Checklist Digital",
                    text: "Registre informações e fotos do veículo na entrada",
                    icon: "📸"
                },
                {
                    title: "PDV Completo",
                    text: "Venda peças e produtos, independente de serviços",
                    icon: "🛍️"
                },
                {
                    title: "Notas Fiscais",
                    text: "Emissão automática de NF-e e NFS-e após serviços",
                    icon: "📄"
                }
            ]
        },
        {
            title: "Financeiro e Relacionamento",
            description: "Controle financeiro e fidelização",
            points: [
                {
                    title: "Pagamentos Integrados",
                    text: "Receba por link ou máquina de cartão com taxas competitivas",
                    icon: "💳"
                },
                {
                    title: "Gestão de Estoque",
                    text: "Controle peças e produtos com alertas automáticos de compra",
                    icon: "📦"
                },
                {
                    title: "Portal do Cliente",
                    text: "Acompanhamento de serviços e histórico de revisões online",
                    icon: "👤"
                },
                {
                    title: "Garage Card",
                    text: "Etiquetas com QR Code para acesso ao histórico do veículo",
                    icon: "🎯"
                }
            ]
        }
    ];

    return (
        <>
            <section className="py-20 bg-gray-50">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-12">
                        <h2 className="text-[#263238] text-3xl md:text-4xl font-bold mb-4">
                            TRANSFORME A ATUAÇÃO DE SUA TROCA DE ÓLEO EM UMA
                            <div className="mt-2">
                                <span className="bg-[#2196F3] text-white px-4 py-2 rounded-lg">
                                    MÁQUINA DE RESULTADOS
                                </span>
                            </div>
                        </h2>
                        <p className="text-[#78909C] text-lg">
                            Desenvolvido para aumentar sua eficiência e lucro
                        </p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                        {features.map((feature, index) => (
                            <div key={index} className="bg-white rounded-xl overflow-hidden shadow-lg">
                                {/*use the color [#263238]*/}
                                <div className="p-6 bg-gradient-to-r from-blue-600 to-blue-700">
                                    <h3 className="text-2xl font-bold text-white mb-2">{feature.title}</h3>
                                    <p className="text-blue-100">{feature.description}</p>
                                </div>
                                <div className="p-6">
                                    <div className="space-y-6">
                                        {feature.points.map((point, idx) => (
                                            <div key={idx} className="flex items-start gap-4">
                                                <div className="text-2xl">{point.icon}</div>
                                                <div>
                                                    <h4 className="font-semibold text-gray-900">{point.title}</h4>
                                                    <p className="text-gray-600 text-sm">{point.text}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section
                className="py-20 relative overflow-hidden"
                style={{
                    background: `linear-gradient(135deg, #2196F3 0%, #1976D2 100%)`,
                }}
            >
                <div className="container mx-auto px-4">
                    <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 items-center gap-8">
                        <div className="flex justify-center lg:justify-start">
                            <img
                                src="/Troca%20de%20Óleo.jpg"
                                alt="Troca de Óleo"
                                className="rounded-lg shadow-lg w-full lg:w-[80%] object-cover"
                            />
                        </div>

                        <div className="text-center lg:text-left">
                            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                                Comece a transformar seu negócio hoje mesmo
                            </h2>
                            <p className="text-blue-100 text-lg mb-8">
                                Agende uma demonstração gratuita e descubra como nossa plataforma pode
                                aumentar seu ticket médio e fidelizar seus clientes
                            </p>
                            <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                                <button
                                    className="group w-full sm:w-auto px-8 py-5 bg-white text-[#263238] rounded-xl font-bold text-lg
                    transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl hover:shadow-black/10"
                                    onClick={() =>
                                        window.open(
                                            "https://sistema.garagehero.com.br/registrar",
                                            "_blank"
                                        )
                                    }
                                    id="cta-feature-button-register"
                                >
                                    <div className="relative flex items-center justify-center gap-2">
                                        <span>Começar Grátis</span>
                                        <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform"/>
                                    </div>
                                    <span className="block text-sm font-normal mt-1 text-gray-500">
              14 dias grátis • Sem cartão
            </span>
                                </button>

                                <button
                                    className="w-full sm:w-auto px-8 py-5 bg-transparent text-white rounded-xl font-bold text-lg
                    border-2 border-white/20 transition-all duration-300 hover:-translate-y-1 hover:bg-white/10"
                                    onClick={() =>
                                        window.open(
                                            "https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda",
                                            "_blank"
                                        )
                                    }
                                    id="cta-feature-button-demo"
                                >
                                    <span className="block">Ver Demonstração</span>
                                    <span className="block text-sm font-normal mt-1 text-gray-200">
              Atendimento personalizado
            </span>
                                </button>
                            </div>
                            <p className="text-blue-100 text-sm mt-6">
                                Já ajudamos mais de 560+ empresas a aumentarem seus resultados
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeaturesSectionWithCTA;